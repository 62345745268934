/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/

.root {
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;

    .left {
      flex: 1;
      //padding-bottom: 36px;

      > div {
        max-width: 500px;
      }
    }
  }

  .nav_buttons {
    display: flex;
    margin-bottom: 18px;
  }

  .organisation_details {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    margin-bottom: 24px;

    > h2 > span {
      font-size: 18px;
    }

    .detail_buttons {
      display: flex;
      align-items: center;
    }
  }

  .first_tables {
    display: flex;

    .users_table {
      flex: 1;
      width: auto;
    }

    .collections_table {
      flex: 1;
      width: auto;
    }
  }

  .collections_container {
    max-height: 250px;

    .header {
      display: flex;
      justify-content: space-between;
      padding: 24px;
    }
  }
}