.root {
  width: 100%;
  height: 100%;

  .edit_buttons {
    @media (min-width: 960px) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .toolbar_button {
    margin-right: 24px;
  }

  td {
    @media (max-width: 960px) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
