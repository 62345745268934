/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/

@import "../../../colors.scss";

.text_input {
  width: 100%;
  margin-bottom: 15px;
}