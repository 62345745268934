/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

.dialog {
  width: 100%;
  max-width: 500px;
  padding: 0;

  .dialog_title {
    padding: 18px 18px 0 18px;

    h6 {
      font-family: Avenir, Helvetica, Arial, "sans-serif";
    }
  }

  .dialog_content {
    padding: 9px 18px;

    .dialog_content_text {

    }

    .dialog_content_text_error {
      color: red;
    }
  }

  .dialog_actions {
    padding: 18px;
    margin: 0;
  }
}