/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/10/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


.start_wifi_journey_dialog {
  overflow-y: unset;

  .start_wifi_journey_dialog_content {
    overflow-y: unset;
  }
  
}
