/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-06.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


.date_picker_wrapper {

  position: relative;
  margin-top: 9px;
  margin-bottom: 9px;

  .date_picker {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    > * {
      width: 100%;
      > * > * {
        width: 100%;
        > :global(.DateInput) {
          width: 100%;
        }
      }
    }
  }
}


//
//.date_range_picker {
//}
//
//.arrow {
//  height: 11px;
//  width: 11px;
//}
//
//:global(.SingleDatePicker) {
//  //box-shadow: $BOX_SHADOW;
//  padding: 0 9px;
//  background-color: white;
//  //color: $TEXT;
//  border-radius: 9px;
//  font-size: 14px;
//  text-transform: none;
//  line-height: 21px;
//  height: 32px;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  width: max-content;
//}
//
//:global(.SingleDatePickerInput) {
//  border: none;
//  display: flex;
//  flex-direction: row;
//}
//
//:global(.SingleDatePickerInput_arrow) {
//  margin: 7px 9px 7px 2px;
//  width: 11px;
//  height: 11px;
//}
//
//:global(.DateInput) {
//  width: auto;
//
//  ::-webkit-input-placeholder {
//    //color: $TEXT;
//  }
//}
//
//:global(.DateInput_input) {
//  font-family: "Museo Sans", sans-serif;
//  font-size: 14px;
//  color: #4f566b !important;
//  padding: 0;
//  width: 80px;
//  border-bottom: none;
//  font-weight: 500;
//}
//
//:global(.DateInput_input__focused) {
//  border: none;
//}
//
//:global(.DateInput_fang) {
//  top: 32px !important;
//}
//
//:global(.SingleDatePicker_picker) {
//  z-index: 9999999;
//  top: 45px !important;
//}
//
//:global(.CalendarDay__selected_span) {
//  //background: $BLUE;
//  color: white;
//  //border: 1px solid $BLUE;
//}
//
//:global(.CalendarDay__selected) {
//  //background: $BLUE_DARK;
//  //color: white;
//  //border: 1px solid $BLUE_DARK;
//}
//
//:global(.CalendarDay__selected:hover) {
//  //background: $BLUE_DARK;
//  //border: 1px solid $BLUE_DARK;
//  //color: white;
//}
//
//:global(.CalendarDay__hovered_span:hover),
//:global(.CalendarDay__hovered_span) {
//  //border: 1px solid $BLUE;
//  //background: $BLUE;
//  //color: white;
//}
//
//:global(.CalendarMonth) {
//  padding: 0 13px;
//}
//
//:global(.CalendarMonthGrid__horizontal) {
//  left: 0;
//  margin-top: 10px;
//}
//
//:global(.CalendarMonth_caption) {
//  color: #3c4257;
//  font-size: 15px;
//  margin-top: 6px;
//  margin-bottom: 40px;
//  padding: 0;
//
//  > strong {
//    font-family: "Open Sans", sans-serif;
//    font-weight: 500;
//    color: #3c4257;
//    font-size: 15px;
//  }
//}
//
//:global(.DayPicker) {
//  border-radius: 9px;
//}
//
//:global(.DayPicker__horizontal ) {
//  width: 300px !important;
//
//  > div {
//    > div {
//      width: auto !important;
//    }
//  }
//}
//
//:global(.DayPicker_transitionContainer__horizontal) {
//  width: auto !important;
//}
//
//:global(.DayPicker_weekHeader) {
//  color: #757575;
//  position: absolute;
//  top: 62px;
//  z-index: 2;
//  text-align: left;
//}
//
//:global(.DayPicker__horizontal .DayPicker_weekHeader:first-of-type) {
//  top: 48px;
//  left: 0;
//  padding: 0 13px;
//}
//
//:global(.DayPicker_weekHeaders__horizontal) {
//  margin-left: 0;
//}
//
//:global(.DayPicker__horizontal .DayPicker_weekHeader) {
//  color: #8792a2;
//  list-style: none;
//  margin: 1px 0;
//  padding-left: 0;
//  padding-right: 0;
//  font-size: 14px;
//}
//
//:global(.DayPicker__horizontal .DayPicker_weekHeader_ul) {
//  list-style: none;
//  margin: 1px 0;
//  padding-left: 0;
//  padding-right: 0;
//  font-size: 14px;
//}
//
//:global(.DayPicker__horizontal .DayPicker_weekHeader_li) {
//  height: 20px;
//  width: 39px;
//
//  > small {
//    font-size: 11px;
//  }
//}
//
//:global(.DayPicker__horizontal .DayPicker_weekHeader:first-of-type) {
//  top: 48px;
//}
//
//:global(.DayPicker__horizontal .DayPickerNavigation_button) {
//  position: absolute;
//  height: 34px;
//  width: 34px;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//}
//
//:global(.DayPicker__horizontal .DayPickerNavigation_button:first-of-type) {
//  border: 0;
//  top: 8px;
//  left: 6px;
//  padding: 0;
//}
//
//:global(.DayPicker__horizontal .DayPickerNavigation_button:nth-of-type(2)) {
//  border: 0;
//  top: 8px;
//  right: 6px;
//  padding: 0;
//}
//
//.next_button {
//  height: 34px;
//  width: 34px;
//  background-color: pink;
//}
