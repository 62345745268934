/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/

@import "../../../colors.scss";


.loader_center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.loader {
  color: $GREY_DARK;
}