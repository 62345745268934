/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 7/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
@import "../../../colors";

.snackbar {

  .snackbar_content {
    font-family: 'Avenir-Roman', Helvetica, Arial, "sans-serif";
    font-weight: bold;
    border-radius: 9px;
    background-color: $GREEN_DARK;

    .snackbar_content_message {
      display: flex;
      align-items: center;

      > span {
        margin-left: 9px;
      }
    }
  }

  .success {
    background-color: $GREEN;
  }

  .error {
    background-color: $RED;
  }

  .warning {
    background-color: $ORANGE;
  }
}

