 .root {
    width: 100%;
    height: 100%;

    .text_input {
      margin-right: 40px;
      width: 320px;
    }
    .error {
      color: red;
      margin: 15px 0;
    }
    .status_field_container {
      margin: 8px;
      margin-top: 15px;
      .error {
        color: red;
        margin: 5px 18px;
      }
    }
    .status_container {
      margin-top: 8px;
    }
    .status {
      margin-right: 8px;
    }
  
    .header {
      display: flex;
      justify-content: space-between;
    }
  
    .nav_buttons {
      display: flex;
      margin-bottom: 18px;
    }
  
    .access_point_details {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      margin-bottom: 24px;
      > h2 > span {
        font-size: 18px;
      }
  
      .detail_buttons {
        display: flex;
        align-items: center;
      }
    }
  
    .first_tables {
      display: flex;
  
      .users_table {
        flex: 1;
        width: auto;
      }
  
      .collections_table {
        flex: 1;
        width: auto;
      }
    }
  
    .collections_container {
      max-height: 250px;
      .header {
        display: flex;
        justify-content: space-between;
        padding: 24px;
      }
    }
}
