/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/

.organisations_container {
  width: 100%;
  height: 100%;

  .table_header_item {
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    .inner {
      height: 100%;
      width: 100%;
      padding-right: 36px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .edit_buttons {
    @media (min-width: 960px) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
