/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 13/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
@import "../../../colors";

.chip {
  font-size: 15px;
  color: $TEXT_GREY;
  text-transform: none;
  padding: 6px;
}

.blue {
  background-color: $BLUE;
  color: $TEXT_WHITE;
}

.green {
  background-color: $GREEN;
  color: $TEXT_WHITE;
}

.red {
  background-color: $RED;
  color: $TEXT_WHITE;
}