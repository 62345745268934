/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/

html, body, #root {
  height: 100%;
  width: 100%;
}

body, p, span, a, li, ol, ul, h1, h2, h3, h4, h5, h6 {
  font-family: "Museo Sans", "museo-sans", "Source Sans Pro", Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Museo Sans", "museo-sans", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 15px;
  background: #FFFFFF;
  background-size: 75%;
  color: #888888;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
  color: #555;
  margin: 0;
}

h2, h3, h4 {
  font-family: "Museo Slab", "museo-slab", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

h1 {
  margin: 0 0 32px;
  font-size: 32px;
  font-weight: 900;
}
