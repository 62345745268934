/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 22/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/

.users_container {
  width: 100%;
  height: 100%;

  .edit_buttons {
    @media (min-width: 960px) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
