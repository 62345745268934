@import "../../../colors";

.chip {
  font-size: 15px;
  color: $TEXT_GREY;
  text-transform: none;
  padding: 0;
  height: auto;
  @media (min-width: 960px) {
    padding: 6px;
  }
}

.blue {
  background-color: $BLUE;
  color: $TEXT_WHITE;
}

.green {
  background-color: $GREEN;
  color: $TEXT_WHITE;
}

.red {
  background-color: $RED;
  color: $TEXT_WHITE;
}
