/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/

.login_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  .banner_logo {
    width: 100%;
    max-width: 350px;
    margin-bottom: 40px;

    img {

    }
  }

  .login_content_container {
    width: 100%;
    max-width: 350px;

    .login_form {
      padding: 25px;
      display: flex;
      flex-direction: column;

      .login_input {
        flex: 1;
        padding-bottom: 30px;
      }
    }
  }
}