.details {

  .text_input {
    margin-left: 10px;
    margin-right: 50px;
    margin-bottom: 20px;
    max-width: 850px;
  }

  .submit_button {
    margin: 20px 0 0 10px;
  }
}
