/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/

@import "../../../colors.scss";

.button {
  height: 36px;
  padding: 0 18px;
  background-color: $GREEN_DARK;
  color: white;
  border-radius: 9px;
  font-size: 15px;
  border: none;
  text-transform: none;
  line-height: 21px;

  &:hover, &.disabled, &.disabled:hover {
    background-color: $GREEN_DARK;
  }
}

.disabled {
  cursor: not-allowed;
}

.red {
  background-color: $RED;

  &:hover, &.disabled, &.disabled:hover {
    background-color: $RED;
  }
}

.yellow {
  background-color: $YELLOW;

  &:hover, &.disabled, &.disabled:hover {
    background-color: $YELLOW;
  }
}

.plain {
  background-color: transparent;
  color: $GREY_DARK;

  &:hover, &.disabled, &.disabled:hover {
    background-color: transparent;
  }
}

.darkGrey {
  background-color: $GREY_DARK;
  color: $TEXT_WHITE;

  &:hover, &.disabled, &.disabled:hover {
    background-color: $GREY_DARK;
  }
}

.green {
  background-color: $GREEN;
  color: $TEXT_WHITE;

  &:hover, &.disabled, &.disabled:hover {
    background-color: $GREEN;
  }
}

.blue {
  background-color: $BLUE;
  color: $TEXT_WHITE;

  &:hover, &.disabled, &.disabled:hover {
    background-color: $BLUE;
  }
}

.pink {
  background-color: $PINK;
  color: $TEXT_WHITE;

  &:hover {
    background-color: $PINK;
  }
}

.orange {
  background-color: $ORANGE;
  color: $TEXT_WHITE;

  &:hover, &.disabled, &.disabled:hover {
    background-color: $ORANGE;
  }
}

.peach {
  background-color: $PEACH;
  color: $TEXT_WHITE;

  &:hover, &.disabled, &.disabled:hover {
    background-color: $PEACH;
  }
}

.border {
  border: 1px solid $GREY_LIGHT;
}