.red_x {
  position: absolute;
  top: -9px;
  right: -8px;
  height: 20px;
  z-index: 100;
  width: 40px;
}

.image_holder {
  cursor: move;
  height: 150px;
  padding: 0 9px 15px;
  display: inline-block;
  position: relative;
}

.image_holder img {
  max-height: 100%;
}

@media (max-width: 767px) {
  .image_holder {
    width: 48%;
  }
}
