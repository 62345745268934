/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 6/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/

.select {
  width: 100%;
  margin-bottom: 15px;
}