/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/

@import "../../../colors.scss";

.dashboard_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: relative;

  .toolbar_pad {
    height: 56px;
    @media (min-width: 600px) {
      height: 64px;

    }
  }
}
.toolbar_pad {
  height: 56px;
  @media (min-width: 600px) {
    height: 64px;

  }
}

.menu_divider {
  background-color: #ffffff33;
}

.app_bar {
  z-index: 12001;
  background-color: white;

  .toolbar {
    .toolbar_logo {
      width: 190px;
      @media (min-width: 600px) {
        width: 200px;

      }
    }
  }
}
.toolbar_logo {
  width: 190px;
  @media (min-width: 600px) {
    width: 200px;

  }
}

.drawer {
  position: relative;
  width: 250px;
  height: 100%;
  background-color: $GREEN_DARK;
}

.menu_item {
  .menu_item_text {
    > span {
      color: white;
    }
  }
}

.dashboard_content_wrapper {

  width: 100%;
  height: 100%;

  .dashboard_content {
    position: relative;
    width: 100%;
    height: calc(100% - 64px);
    overflow-y: auto;
    overflow-x: hidden;

    .arrow_wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 14px 0;
    }
  }
}