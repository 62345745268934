/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 4/12/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "../../../colors.scss";

.check_box {

  .checkbox_root {
    color: $GREEN_DARK;
  }

  .checkbox_inner {

  }
}